import React, { Suspense, useLayoutEffect } from 'react'

import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'react-snackbar-alert'

import '@sponte/lib-utils/dist/yup-validations'

import createTheme from '@sponte/lib-themes/dist/medplus'
import { SptThemeProvider } from '@sponte/lib-themes/dist/ThemeProvider'

import { SptGlobalCss } from '@sponte/lib-components/dist/elements/GlobalCss'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'
import { SptSnackbar } from '@sponte/lib-components/dist/atoms/Snackbar'

import { API_URL } from 'config'

import { extendApi as extendApiPortal } from 'api/portal'

import i18n from 'i18n'

import * as authService from 'services/auth'
import * as tenantsService from 'services/tenants'

import { AuthProvider } from 'providers/auth'
import { TenantProvider } from 'providers/tenant'

// eslint-disable-next-line
import { ReactQueryProvider } from 'providers/reactQuery'

import DevTools from '../dev-tools'

const addToken = (request) => {
  const token = authService.getToken()

  if (token) {
    request.headers.set('authorization', `Bearer ${token}`)
  }
}

const addAppId = (request) => {
  request.headers.set('X-App-Id', process.env.REACT_APP_APP_ID)
}

const addAppSecret = (request) => {
  request.headers.set('X-App-Secret', process.env.REACT_APP_APP_SECRET)
}

const addTenant = (request) => {
  const tenantId = tenantsService.getId()

  if (tenantId) {
    request.headers.set('x-tenant-id', tenantId)
  }
}

extendApiPortal({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [addTenant, addAppId, addAppSecret, addToken]
  }
})

const theme = createTheme({
  logo: `/assets/logo.svg`,
  illustration: `/assets/illustration.svg`,
  notFound: `/assets/notFound.svg`,
  login: `/assets/login.svg`
})

const Providers = ({ children }) => {
  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    const subdominio = params.get('subdominio')

    if (token || subdominio) {
      if (token) {
        authService.setToken(token)
      }

      if (subdominio) {
        tenantsService.setSubdominio(subdominio)
      }

      window.history.replaceState({}, document.title, `${window.location.origin}${window.location.pathname}`)
    }
  }, [])

  return (
    <SptThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider
          timeout={3000}
          sticky={false}
          dismissable={false}
          position="bottom-left"
          component={SptSnackbar}
        >
          <SptGlobalCss />

          <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
            <ReactQueryProvider>
              <TenantProvider
                bootstrap={tenantsService.bootstrap}
                onAccess={tenantsService.access}
                onRemove={tenantsService.remove}
              >
                <AuthProvider
                  bootstrap={authService.bootstrap}
                  onLogin={authService.login}
                  onLogout={authService.logout}
                  onChangeEu={authService.changeEu}
                  onChangePassword={authService.changePassword}
                >
                  <BrowserRouter>
                    {children}
                    <DevTools />
                  </BrowserRouter>
                </AuthProvider>
              </TenantProvider>
            </ReactQueryProvider>
          </Suspense>
        </SnackbarProvider>
      </I18nextProvider>
    </SptThemeProvider>
  )
}

export default Providers
