export default {
  geral: {
    app: 'MedPlus Agendamento Online',
    proximo: 'Próximo',
    voltar: 'Voltar',
    confirmar: 'Confirmar',
    data: 'Data',
    components: {
      areaAtuacao: 'Área de Atuação',
      unidadeAtendimento: 'Unidade de Atendimento',
      horarios: 'Horários',
      agendar: 'Agendar',
      entre: 'Entre',
      cadastrese: 'Cadastre-se',
      ou: 'ou',
      fotoAdicionadaSucesso: 'Foto adicionada com sucesso!',
      fotoRemovidaSucesso: 'Foto removida com sucesso!',
      raca: 'Raça',
      particular: 'Particular',
      tipoConsulta: 'Tipo de consulta',
      presencial: 'Presencial',
      teleconsulta: 'Teleconsulta'
    },
    navegacao: {
      titulo: 'Agendamento Online',
      agendamento: 'Agendamento',
      favoritos: 'Favoritos',
      agendar: 'Agendar',
      historico: 'Histórico',
      laudos: 'Laudos',
      exames: 'Exames',
      teleconsultas: 'Teleconsultas'
    }
  },

  modulos: {
    agendamento: {
      especialidade: {
        titulo: 'Especialidades',
        naoEncontrado: 'Nenhum resultado encontrado',
        naoEncontradoDetalhes: 'Use palavras-chave diferentes ou remova os filtros de pesquisa'
      },

      clinica: {
        titulo: 'Clínicas',
        endereco: 'Endereço',
        bairro: 'Bairro',
        cidade: 'Cidade',
        telefone: 'Telefone',
        distancia: 'Esta clínica fica a metros {{metros}} de sua localização atual'
      },

      operadoras: {
        titulo: 'Operadoras de Saúde',
        tituloOperadora: 'Particular ou Operadora de Saúde',
        subtituloOperadora: 'Selecione se o agendamento será Particular ou por Operadora de Saúde',
        operadora: 'Operadora de Saúde',
        plano: 'Plano'
      },

      profissionais: {
        titulo: 'Horários e Profissionais',
        turnos: {
          madrugada: 'Madrugada',
          manha: 'Manhã',
          tarde: 'Tarde',
          noite: 'Noite'
        },
        profissional: 'Profissional',
        areaAtuacao: 'Áreas de Atuação',
        turno: 'Turno',
        filtros: 'Filtros',
        filtrar: 'Filtrar'
      },

      paciente: {
        titulo: 'Paciente',
        selecionar: 'Selecionar',
        adicionarPaciente: 'Adicionar novo paciente',
        adicionarDependente: 'Adicionar novo dependente',
        ok: 'Ok',
        cadastroPaciente: {
          titulo: 'Novo Dependente',
          subtitulo: 'Dados do Dependente',
          cpf: 'CPF',
          nome: 'Nome completo',
          sexo: 'Sexo',
          dataNascimento: 'Data de nascimento',
          celular: 'Telefone celular',
          telefone: 'Telefone fixo',
          email: 'E-mail',
          parentesco: 'Parentesco',
          cidade: 'Cidade',
          pais: 'País',
          uf: 'UF',
          tipoLogradouro: 'Tipo Logradouro',
          buttonNovoCadastro: 'Cadastrar novo paciente',
          buttonAdicionarCadastro: 'Adicionar cadastro',
          successo: 'Paciente vinculado com sucesso!',
          validacoes: {
            cpf: 'CPF inválido',
            cpfObrigatorio: 'Informe o CPF',
            cpfExistente: 'Já existe um paciente cadastrado com este CPF',
            nome: 'Informe o nome completo',
            dataNascimento: 'Informe a data de nascimento',
            dataNascimentoMaior: 'Data de nascimento maior que a data atual',
            celular: 'Informe o telefone celular',
            email: 'E-mail inválido',
            parentesco: 'Informe o parentesco',
            emailObrigatorio: 'Informe o e-mail'
          }
        }
      },

      turnos: {
        manha: 'Manhã',
        tarde: 'Tarde',
        noite: 'Noite',
        madrugada: 'Madrugada'
      },

      confirmacao: {
        titulo: 'Confirmação',
        subtitulo: 'Tudo certo para o seu agendamento?',
        especialidade: 'Especialidade',
        clinica: 'Clínica',
        profissional: 'Profissional',
        dataHora: 'Data e hora',
        operadoraPlano: 'Operadora de Saúde e Plano',
        paciente: 'Paciente',
        orientacao: 'Orientações da clínica',
        verMais: 'Ver mais',
        verMenos: 'Ver menos'
      },
      questionario: {
        titulo: 'Questionário de pré-consulta',
        subtitulo: 'Responda o questionário de pré-consulta para agilizar o seu atendimento',
        buttonResponder: 'Responder',
        buttonSalvar: 'Salvar',
        successo: 'Questionário salvo com sucesso!',
        erro: 'Atenção, para confirmar o agendamento preencha o questionário de pré-consulta'
      },

      conclusao: {
        titulo: 'Parabéns! Você concluiu o seu agendamento.',
        subtitulo: 'O que deseja fazer agora?',
        novo: 'Novo agendamento',
        historico: 'Histórico de agendamentos',
        perfil: 'Atualizar meu perfil',
        pagamento: 'Realizar Pagamento',
        pagamentoRealizado: 'Pagamento realizado com sucesso!',
        subtituloPagamento: 'Deseja fazer o pagamento da sua consulta? Para isso clique no botão abaixo: '
      }
    },
    login: {
      login: 'Informe o E-mail ou CPF',
      senha: 'Informe a senha',
      button: 'Acessar',
      link: 'Esqueci minha senha',
      titulo: 'Primeira vez no Agendamento Online?',
      subtitulo: ' Crie uma conta grátis e marque consultas a qualquer hora, em qualquer lugar, sem espera!',
      buttonCriar: 'Criar conta',
      facaLogin: 'Faça seu login'
    },
    cadastroConta: {
      titulo: 'Cadastrar conta',
      subtitulo: 'Crie sua conta, é uma vez só e você poderá agendar sempre que quiser.',
      cpf: 'CPF',
      nome: 'Nome completo',
      sexo: 'Sexo',
      dataAniversario: 'Data de nascimento',
      telefoneCelular: 'Telefone celular',
      telefoneFixo: 'Telefone fixo',
      midia: 'Como você conheceu a clínica?',
      email: 'E-mail',
      senha: 'Senha',
      confirmacaoSenha: 'Confirmação de Senha',
      buttonVoltar: 'voltar',
      buttoCriar: 'criar conta',
      tituloPoliticaPrivacidade: 'Política de Privacidade',
      buttonAceitoPoliticaPrivacidade: 'Aceito a Política de Privacidade',
      validacoes: {
        cpf: 'CPF inválido',
        cpfObrigatorio: 'Informe o CPF',
        nome: 'Informe o nome completo',
        dataAniversario: 'Informe a data de nascimento',
        dataAniversarioMaior: 'Data de nascimento maior que a data atual',
        telefoneCelular: 'Informe o telefone celular',
        email: 'E-mail inválido',
        sexo: 'Informe o Sexo',
        emailObrigatorio: 'Informe o e-mail',
        senha: 'Informe a senha',
        confirmacaoSenha: 'Informe a confirmação de senha',
        senhasDiferentes: 'As senhas não coincidem',
        senhaInvalida: 'Senha inválida'
      },
      sendCode: {
        titulo: 'Precisamos validar sua conta',
        subtitulo: 'Como deseja obter o código de validação?',
        button: 'Enviar',
        email: 'Enviar mensagem para o e-mail',
        sms: 'Enviar mensagem para o celular',
        codigoEnviadoSucesso: 'Código enviado com sucesso!'
      }
    },
    forgot: {
      titulo: 'Redefinir senha',
      subtitulo: 'Insira seu e-mail ou telefone para redefinirmos a sua senha.',
      placeholderEmail: 'Digite aqui o seu e-mail',
      placeholderCelular: 'Digite aqui o seu telefone celular',
      button: 'Continuar',
      buttonCancelar: 'Cancelar',
      emailValido: 'Informe um e-mail válido',
      ou: 'ou'
    },
    sendCode: {
      titulo: 'Precisamos verificar sua identidade',
      subtitulo: 'Como você deseja receber o código de segurança para redefinir sua senha?',
      button: 'Enviar',
      buttonOutlined: 'Não é você?',
      email: 'Enviar o código por e-mail',
      sms: 'Enviar o código por SMS'
    },
    authVerifyCode: {
      titulo: 'Verifique o seu ',
      subtitulo: 'Enviamos um código de confirmação para o ',
      email: 'e-mail',
      telefone: 'telefone',
      sms: 'sms',
      placeholder: 'Digite aqui o código de validação',
      button: 'Confirmar',
      auxilio: 'Caso não receba o ',
      auxilio2: ', você poderá enviar outra solicitação em:',
      buttonOutlined: 'Reenviar o código',
      buttonTentar: 'Tentar de outra forma',
      obrigatorio: 'Informe o código de validação',
      sucesso: 'Código reenviado com sucesso!'
    },
    changePassword: {
      titulo: 'Alterar a Senha',
      subtitulo: 'Informe abaixo a nova senha de acesso desejada',
      senha: 'Senha',
      confirmacaoSenha: 'Confirmação de Senha',
      button: 'Confirmar',
      senhaAlterada: 'Senha alterada com sucesso!',
      validacoes: {
        senha: 'Informe a senha',
        minimoCaracteres: 'A senha deve ter no mínimo 8 caracteres',
        confirmacaoSenha: 'Informa a confirmação de senha',
        senhasDiferentes: 'As senhas não coincidem',
        senhaInvalida: 'Senha inválida'
      }
    },
    historico: {
      titulo: 'Histórico',
      clinica: 'Clínica',
      profissional: 'Profissional',
      especialidade: 'Especialidade',
      duracao: 'Duração',
      status: 'Status',
      operadoraPlano: 'Operadora de Saúde e Plano',
      paciente: 'Paciente',
      tipoAgendamento: 'Tipo de Agendamento',
      orientacoesExames: 'Orientações para os Exames',
      orientacoesClinica: 'Orientações da Clínica',
      valor: 'Valor',
      cancelar: 'Cancelar',
      nenhumAgendamento: 'Nenhum agendamento encontrado',
      desejaCancelar: 'Deseja realmente cancelar o agendamento?',
      voltar: 'voltar',
      confirmar: 'confirmar',
      agendamentoCancelado: 'Agendamento cancelado com sucesso!'
    },
    expiredPassword: {
      titulo: 'Solicitação de troca de senha',
      subtitulo:
        'Conforme as resoluções do Conselho Federal de Medicina, você deverá trocar a sua senha em um período de 30 a 180 dias ou quando solicitado por um administrador',
      senhaAtual: 'Senha atual',
      novaSenha: 'Nova senha',
      confirmacaoSenha: 'Confirmação da senha',
      solicitarTroca: 'Solicitar troca da senha',
      acessar: 'acessar',
      senhaAlterada: 'Senha alterada com sucesso!',
      validacoes: {
        senhaAtual: 'Informe a senha atual',
        novaSenha: 'Informe a nova senha',
        confirmacaoSenha: 'Informe a confirmação da senha',
        senhasDiferentes: 'As senhas não coincidem',
        solicitarTroca: 'Informe os dias para solicitar uma nova troca de senha'
      }
    },
    perfil: {
      salvar: 'Salvar',
      cancelar: 'Cancelar',
      sucesso: 'Dados salvos com sucesso',
      dadosBasicos: {
        tab: 'dados básicos',
        tituloDados: 'DADOS',
        subtituloDados: 'Principais dados do cliente',
        nome: 'Nome completo',
        dataNascimento: 'Data de nascimento',
        cpf: 'CPF',
        nomeSocial: 'Nome social',
        tituloContato: 'CONTATO',
        subtituloContato: 'Informe o contato principal',
        celular: 'Telefone celular',
        telefone: 'Telefone fixo',
        email: 'E-mail',
        naoReceberSMS: 'Não desejo receber SMS Corporativo',
        naoReceberSMSMarketing: 'Não desejo receber SMS Marketing',
        naoReceberEmail: 'Não desejo receber e-mail',
        tituloNecessidadesEspeciais: 'NECESSIDADES ESPECIAIS',
        subtituloNecessidadesEspeciais: 'Possui alguma necessidade especial?',
        tituloResponsaveis: 'RESPONSÁVEIS',
        tituloDependentes: 'DEPENDENTES',
        remover: 'Remover',
        desejaRemover: 'Deseja realmente remover?',
        responsavelRemovido: 'Responsável removido com sucesso',
        dependenteRemovido: 'Dependente removido com sucesso',
        necessidadesEspeciais: {
          obeso: 'Obeso',
          gestante: 'Gestante',
          lactanteCriancaColo: 'Lactante e/ou criança de colo',
          deficiencia: 'Deficiente físico',
          idoso: 'Idoso'
        },
        validacoes: {
          cpf: 'CPF inválido',
          cpfObrigatorio: 'Informe o CPF',
          nome: 'Informe o nome completo',
          dataNascimento: 'Informe a data de nascimento',
          dataNascimentoMaior: 'Data de nascimento maior que a data atual'
        }
      },
      documentos: {
        selecioneGrupo: 'Selecione um grupo para adicionar',
        tituloRegistroGeral: 'REGISTRO GERAL',
        rg: 'RG',
        emissor: 'Emissor',
        rgEmissao: 'Data de expedição',
        nomeMae: 'Nome da mãe',
        nomePai: 'Nome do pai',
        naturalidade: 'Naturalidade',
        tituloPassaporte: 'PASSAPORTE',
        pais: 'País',
        numeroPassaporte: 'Passaporte',
        dataEntradaBrasil: 'Data de entrada',
        tituloEscolaridade: 'ESCOLARIDADE',
        escolaridade: 'Escolaridade',
        tituloTituloEleitor: 'TÍTULO DE ELEITOR',
        tituloEleitor: 'Título de eleitor',
        zonaEleitor: 'Zona eleitoral',
        sessaoEleitor: 'Seção'
      },
      endereco: {
        tituloDados: 'DADOS',
        cep: 'CEP',
        pais: 'País',
        cidade: 'Cidade',
        uf: 'UF',
        logradouro: 'Logradouro',
        tipoLogradouro: 'Tipo logradouro',
        endereco: 'Endereço',
        numero: 'Número',
        bairro: 'Bairro',
        complemento: 'Complemento'
      },
      acesso: {
        tituloAlterarSenha: 'ALTERAR SENHA',
        senhaAtual: 'Senha atual',
        novaSenha: 'Nova senha',
        confirmacaoSenha: 'Confirmação da senha',
        alterarSenha: 'ALTERAR SENHA',
        tituloTrocaSenha: 'TROCA DE SENHA',
        solicitarTrocaSenha: 'Solicitar troca da senha',
        tituloDadosAcessoPortal: 'DADOS DE ACESSO',
        email: 'E-mail',
        cpf: 'CPF',
        inativarAcessoPortal: 'INATIVAR LOGIN DE ACESSO',
        mensagemInativarAcessoPortal:
          'Caro paciente, ao inativar o login você não poderá mais realizar agendamentos com este usuário. Deseja realmente inativar o login?',
        inativar: 'Inativar',
        solicitarNovaSenhaEmDias: {
          30: '30 dias',
          60: '60 dias',
          90: '90 dias',
          180: '180 dias'
        },
        validacoes: {
          senhaAtual: 'Informe a senha atual',
          novaSenha: 'Informe a nova senha',
          confirmacaoSenha: 'Informe a confirmação da senha',
          senhasDiferentes: 'As senhas não coincidem',
          solicitarTroca: 'Informe os dias para solicitar uma nova troca de senha',
          senhaAlterada: 'Senha alterada com sucesso!',
          senhaInvalida: 'Senha inválida'
        }
      }
    },
    teleconsultas: {
      teleconsulta: 'Teleconsulta'
    }
  }
}
