/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://api.agendamento.medplusweb.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const mutationAgendar = (options) => mutationFn('post', '/api/v1/agendamento', {}, {}, options)
export const useMutationAgendar = (config, options) => useMutation(mutationFn('post', '/api/v1/agendamento', {}, {}, options), config)

export const mutationCancelarAgendamento = (pathParams, options) => mutationFn('delete', '/api/v1/agendamento/{id}', pathParams, {}, options)
export const useMutationCancelarAgendamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamento/{id}', pathParams, {}, options), config)

export const queryBuscarHistorico = (queryParams, options) => queryFn(options)('/api/v1/agendamento/historico', queryParams)
export const useQueryBuscarHistorico = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamento/historico', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarHistorico.queryKey = '/api/v1/agendamento/historico'

export const queryBuscarContas = (queryParams, options) => queryFn(options)('/api/v1/contas', queryParams)
export const useQueryBuscarContas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarContas.queryKey = '/api/v1/contas'

export const queryExibirValores = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/exibir-valores', pathParams)
export const useQueryExibirValores = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/exibir-valores', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryExibirValores.queryKey = '/api/v1/contas/{id}/exibir-valores'

export const queryAtendeParticular = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/atende-particular', pathParams)
export const useQueryAtendeParticular = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/atende-particular', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendeParticular.queryKey = '/api/v1/contas/{id}/atende-particular'

export const queryAtendeTeleconsulta = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/atende-teleconsulta', pathParams)
export const useQueryAtendeTeleconsulta = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/atende-teleconsulta', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendeTeleconsulta.queryKey = '/api/v1/contas/{id}/atende-teleconsulta'

export const queryBuscarConvenios = (queryParams, options) => queryFn(options)('/api/v1/convenios', queryParams)
export const useQueryBuscarConvenios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarConvenios.queryKey = '/api/v1/convenios'

export const queryBuscarDatas = (queryParams, options) => queryFn(options)('/api/v1/datas-disponiveis', queryParams)
export const useQueryBuscarDatas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/datas-disponiveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarDatas.queryKey = '/api/v1/datas-disponiveis'

export const queryGetTiposPessoa = (options) => queryFn(options)('/api/v1/enums/tipos-pessoa')
export const useQueryGetTiposPessoa = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-pessoa'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetTiposPessoa.queryKey = '/api/v1/enums/tipos-pessoa'

export const queryGetTiposSexo = (options) => queryFn(options)('/api/v1/enums/tipos-sexo')
export const useQueryGetTiposSexo = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-sexo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetTiposSexo.queryKey = '/api/v1/enums/tipos-sexo'

export const queryGetTiposParentescos = (options) => queryFn(options)('/api/v1/enums/tipos-parentescos')
export const useQueryGetTiposParentescos = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-parentescos'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetTiposParentescos.queryKey = '/api/v1/enums/tipos-parentescos'

export const queryGetTiposEstadosCivis = (options) => queryFn(options)('/api/v1/enums/tipos-estados-civis')
export const useQueryGetTiposEstadosCivis = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-estados-civis'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetTiposEstadosCivis.queryKey = '/api/v1/enums/tipos-estados-civis'

export const queryGetEscolaridades = (options) => queryFn(options)('/api/v1/enums/tipos-escolaridades')
export const useQueryGetEscolaridades = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-escolaridades'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetEscolaridades.queryKey = '/api/v1/enums/tipos-escolaridades'

export const queryGetOrgaosEmissores = (options) => queryFn(options)('/api/v1/enums/tipos-orgaos-emissores')
export const useQueryGetOrgaosEmissores = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-orgaos-emissores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGetOrgaosEmissores.queryKey = '/api/v1/enums/tipos-orgaos-emissores'

export const queryObterTiposAgendamentos = (pathParams, options) => queryFn(options)('/api/v1/enums/tipos-agendamentos/{contaId}', pathParams)
export const useQueryObterTiposAgendamentos = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-agendamentos/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId, ...config }
})
useQueryObterTiposAgendamentos.queryKey = '/api/v1/enums/tipos-agendamentos/{contaId}'

export const queryObterTiposNecessidadesEspeciais = (options) => queryFn(options)('/api/v1/enums/tipos-necessidades-especiais')
export const useQueryObterTiposNecessidadesEspeciais = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryObterTiposNecessidadesEspeciais.queryKey = '/api/v1/enums/tipos-necessidades-especiais'

export const queryObterTiposErros = (options) => queryFn(options)('/api/v1/enums/tipos-mensagens-erro')
export const useQueryObterTiposErros = (config, options) => useQuery({
  queryKey: ['/api/v1/enums/tipos-mensagens-erro'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryObterTiposErros.queryKey = '/api/v1/enums/tipos-mensagens-erro'

export const queryBuscarEspecialidades = (queryParams, options) => queryFn(options)('/api/v1/especialidades', queryParams)
export const useQueryBuscarEspecialidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarEspecialidades.queryKey = '/api/v1/especialidades'

export const queryObterPorId = (pathParams, options) => queryFn(options)('/api/v1/especialidades/{id}', pathParams)
export const useQueryObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObterPorId.queryKey = '/api/v1/especialidades/{id}'

export const queryBuscarEspecialidadesPortal = (queryParams, options) => queryFn(options)('/api/v1/especialidades-portal', queryParams)
export const useQueryBuscarEspecialidadesPortal = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades-portal', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarEspecialidadesPortal.queryKey = '/api/v1/especialidades-portal'

export const queryBuscarPaises = (queryParams, options) => queryFn(options)('/api/v1/localidades/paises', queryParams)
export const useQueryBuscarPaises = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarPaises.queryKey = '/api/v1/localidades/paises'

export const queryBuscarEstadosPorPais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/paises/{paisId}/estados', pathParams, queryParams)
export const useQueryBuscarEstadosPorPais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises/{paisId}/estados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.paisId, ...config }
})
useQueryBuscarEstadosPorPais.queryKey = '/api/v1/localidades/paises/{paisId}/estados'

export const queryBuscarEstados = (queryParams, options) => queryFn(options)('/api/v1/localidades/estados', queryParams)
export const useQueryBuscarEstados = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarEstados.queryKey = '/api/v1/localidades/estados'

export const queryBuscarCidadesPorEstado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/estados/{estadoId}/cidades', pathParams, queryParams)
export const useQueryBuscarCidadesPorEstado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados/{estadoId}/cidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.estadoId, ...config }
})
useQueryBuscarCidadesPorEstado.queryKey = '/api/v1/localidades/estados/{estadoId}/cidades'

export const queryBuscarCidades = (queryParams, options) => queryFn(options)('/api/v1/localidades/cidades', queryParams)
export const useQueryBuscarCidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarCidades.queryKey = '/api/v1/localidades/cidades'

export const queryBuscarBairrosPorCidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/cidades/{cidadeId}/bairros', pathParams, queryParams)
export const useQueryBuscarBairrosPorCidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades/{cidadeId}/bairros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.cidadeId, ...config }
})
useQueryBuscarBairrosPorCidade.queryKey = '/api/v1/localidades/cidades/{cidadeId}/bairros'

export const queryBuscarBairros = (queryParams, options) => queryFn(options)('/api/v1/localidades/bairros', queryParams)
export const useQueryBuscarBairros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/bairros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarBairros.queryKey = '/api/v1/localidades/bairros'

export const mutationCadastrarBairro = (options) => mutationFn('post', '/api/v1/localidades/bairros', {}, {}, options)
export const useMutationCadastrarBairro = (config, options) => useMutation(mutationFn('post', '/api/v1/localidades/bairros', {}, {}, options), config)

export const queryBuscarTiposLogradouros = (queryParams, options) => queryFn(options)('/api/v1/localidades/tipos-logradouros', queryParams)
export const useQueryBuscarTiposLogradouros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/tipos-logradouros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarTiposLogradouros.queryKey = '/api/v1/localidades/tipos-logradouros'

export const queryBuscarCep = (pathParams, options) => queryFn(options)('/api/v1/localidades/busca-cep/{cep}', pathParams)
export const useQueryBuscarCep = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/busca-cep/{cep}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.cep, ...config }
})
useQueryBuscarCep.queryKey = '/api/v1/localidades/busca-cep/{cep}'

export const queryBuscarMidias = (options) => queryFn(options)('/api/v1/midias')
export const useQueryBuscarMidias = (config, options) => useQuery({
  queryKey: ['/api/v1/midias'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarMidias.queryKey = '/api/v1/midias'

export const queryBuscaPacienteCpf = (pathParams, options) => queryFn(options)('/api/v1/pacientes/busca-cpf/{cpf}', pathParams)
export const useQueryBuscaPacienteCpf = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/busca-cpf/{cpf}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.cpf, ...config }
})
useQueryBuscaPacienteCpf.queryKey = '/api/v1/pacientes/busca-cpf/{cpf}'

export const queryBuscaDadosPerfil = (pathParams, options) => queryFn(options)('/api/v1/pacientes/{id}', pathParams)
export const useQueryBuscaDadosPerfil = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBuscaDadosPerfil.queryKey = '/api/v1/pacientes/{id}'

export const mutationAtualizarPerfilPaciente = (pathParams, options) => mutationFn('put', '/api/v1/pacientes/{id}', pathParams, {}, options)
export const useMutationAtualizarPerfilPaciente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pacientes/{id}', pathParams, {}, options), config)

export const mutationCadastrarPacienteDependente = (options) => mutationFn('post', '/api/v1/pacientes/cadastrar-paciente-dependente', {}, {}, options)
export const useMutationCadastrarPacienteDependente = (config, options) => useMutation(mutationFn('post', '/api/v1/pacientes/cadastrar-paciente-dependente', {}, {}, options), config)

export const mutationVincularPacienteDependente = (options) => mutationFn('put', '/api/v1/pacientes/vincular-paciente-dependente', {}, {}, options)
export const useMutationVincularPacienteDependente = (config, options) => useMutation(mutationFn('put', '/api/v1/pacientes/vincular-paciente-dependente', {}, {}, options), config)

export const mutationDesvincularPacienteDependente = (pathParams, options) => mutationFn('delete', '/api/v1/pacientes/desvincular-paciente-dependente/{pacienteDependenteId}', pathParams, {}, options)
export const useMutationDesvincularPacienteDependente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pacientes/desvincular-paciente-dependente/{pacienteDependenteId}', pathParams, {}, options), config)

export const mutationDesvincularPacienteResponsavel = (pathParams, options) => mutationFn('delete', '/api/v1/pacientes/desvincular-paciente-responsavel/{pacienteResponsavelId}', pathParams, {}, options)
export const useMutationDesvincularPacienteResponsavel = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pacientes/desvincular-paciente-responsavel/{pacienteResponsavelId}', pathParams, {}, options), config)

export const queryBuscaPacientesAgendamento = (queryParams, options) => queryFn(options)('/api/v1/pacientes/pacientes-agendamento', queryParams)
export const useQueryBuscaPacientesAgendamento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/pacientes-agendamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscaPacientesAgendamento.queryKey = '/api/v1/pacientes/pacientes-agendamento'

export const queryBuscaPacientesResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pacientes/{id}/responsaveis', pathParams, queryParams)
export const useQueryBuscaPacientesResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBuscaPacientesResponsaveis.queryKey = '/api/v1/pacientes/{id}/responsaveis'

export const queryBuscaPacientesDependentes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pacientes/{id}/dependentes', pathParams, queryParams)
export const useQueryBuscaPacientesDependentes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}/dependentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBuscaPacientesDependentes.queryKey = '/api/v1/pacientes/{id}/dependentes'

export const queryPermiteAgendarPaciente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pacientes/{id}/permite-agendar', pathParams, queryParams)
export const useQueryPermiteAgendarPaciente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}/permite-agendar', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermiteAgendarPaciente.queryKey = '/api/v1/pacientes/{id}/permite-agendar'

export const queryPossuiRetornoPaciente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pacientes/{id}/possui-retorno', pathParams, queryParams)
export const useQueryPossuiRetornoPaciente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}/possui-retorno', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPossuiRetornoPaciente.queryKey = '/api/v1/pacientes/{id}/possui-retorno'

export const mutationAtualizarFotoPaciente = (pathParams, options) => mutationFn('put', '/api/v1/pacientes/{id}/atualizar-foto', pathParams, {}, options)
export const useMutationAtualizarFotoPaciente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pacientes/{id}/atualizar-foto', pathParams, {}, options), config)

export const mutationCapturarTransacao = (options) => mutationFn('post', '/api/v1/pagarme/capturar-transacao', {}, {}, options)
export const useMutationCapturarTransacao = (config, options) => useMutation(mutationFn('post', '/api/v1/pagarme/capturar-transacao', {}, {}, options), config)

export const queryBuscarProfissionaisHorarios = (queryParams, options) => queryFn(options)('/api/v1/profissionais/medicos-horarios', queryParams)
export const useQueryBuscarProfissionaisHorarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/profissionais/medicos-horarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarProfissionaisHorarios.queryKey = '/api/v1/profissionais/medicos-horarios'

export const queryBuscarProfissionais = (queryParams, options) => queryFn(options)('/api/v1/profissionais/medicos', queryParams)
export const useQueryBuscarProfissionais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/profissionais/medicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarProfissionais.queryKey = '/api/v1/profissionais/medicos'

export const queryBuscarQuestionarioAgendamentoOnline = (options) => queryFn(options)('/api/v1/questionarios/questionario-agendamento-online')
export const useQueryBuscarQuestionarioAgendamentoOnline = (config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/questionario-agendamento-online'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarQuestionarioAgendamentoOnline.queryKey = '/api/v1/questionarios/questionario-agendamento-online'

export const mutationCadastrarRespostas = (options) => mutationFn('post', '/api/v1/questionarios/respostas', {}, {}, options)
export const useMutationCadastrarRespostas = (config, options) => useMutation(mutationFn('post', '/api/v1/questionarios/respostas', {}, {}, options), config)

export const queryBuscarRacas = (options) => queryFn(options)('/api/v1/racas')
export const useQueryBuscarRacas = (config, options) => useQuery({
  queryKey: ['/api/v1/racas'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarRacas.queryKey = '/api/v1/racas'

export const queryObterPorSubdominio = (pathParams, options) => queryFn(options)('/api/v1/tenants/{subdominio}', pathParams)
export const useQueryObterPorSubdominio = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{subdominio}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.subdominio, ...config }
})
useQueryObterPorSubdominio.queryKey = '/api/v1/tenants/{subdominio}'

export const queryBuscarTermoUso = (options) => queryFn(options)('/api/v1/termos/termo-uso')
export const useQueryBuscarTermoUso = (config, options) => useQuery({
  queryKey: ['/api/v1/termos/termo-uso'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarTermoUso.queryKey = '/api/v1/termos/termo-uso'

export const queryBuscarTermoConsentimento = (options) => queryFn(options)('/api/v1/termos/termo-consentimento')
export const useQueryBuscarTermoConsentimento = (config, options) => useQuery({
  queryKey: ['/api/v1/termos/termo-consentimento'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBuscarTermoConsentimento.queryKey = '/api/v1/termos/termo-consentimento'

export const queryEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEu.queryKey = '/api/v1/usuarios/eu'

export const queryExisteCadastro = (queryParams, options) => queryFn(options)('/api/v1/usuarios/existe-cadastro', queryParams)
export const useQueryExisteCadastro = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/existe-cadastro', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryExisteCadastro.queryKey = '/api/v1/usuarios/existe-cadastro'

export const queryObterDadosRecuperacaoSenha = (queryParams, options) => queryFn(options)('/api/v1/usuarios/dados-recuperacao', queryParams)
export const useQueryObterDadosRecuperacaoSenha = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/dados-recuperacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryObterDadosRecuperacaoSenha.queryKey = '/api/v1/usuarios/dados-recuperacao'

export const mutationLogar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationLogar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const mutationRegister = (options) => mutationFn('post', '/api/v1/usuarios/registrar', {}, {}, options)
export const useMutationRegister = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/registrar', {}, {}, options), config)

export const mutationTrocarSenha = (options) => mutationFn('post', '/api/v1/usuarios/trocar-senha', {}, {}, options)
export const useMutationTrocarSenha = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/trocar-senha', {}, {}, options), config)

export const mutationSendConfirmationToken = (options) => mutationFn('post', '/api/v1/usuarios/enviar-codigo-confirmacao', {}, {}, options)
export const useMutationSendConfirmationToken = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/enviar-codigo-confirmacao', {}, {}, options), config)

export const mutationConfirmUser = (options) => mutationFn('post', '/api/v1/usuarios/confirmar-usuario', {}, {}, options)
export const useMutationConfirmUser = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/confirmar-usuario', {}, {}, options), config)

export const mutationSendPasswordRecoveryToken = (options) => mutationFn('post', '/api/v1/usuarios/enviar-codigo-recuperacao-senha', {}, {}, options)
export const useMutationSendPasswordRecoveryToken = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/enviar-codigo-recuperacao-senha', {}, {}, options), config)

export const mutationVerifyPasswordRecoveryToken = (options) => mutationFn('post', '/api/v1/usuarios/verificar-codigo-recuperacao-senha', {}, {}, options)
export const useMutationVerifyPasswordRecoveryToken = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/verificar-codigo-recuperacao-senha', {}, {}, options), config)

export const mutationConfirmPasswordRecoveryToken = (options) => mutationFn('post', '/api/v1/usuarios/confirmar-recuperacao-senha', {}, {}, options)
export const useMutationConfirmPasswordRecoveryToken = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/confirmar-recuperacao-senha', {}, {}, options), config)

export const mutationRemoverAcesso = (options) => mutationFn('delete', '/api/v1/usuarios/remover-acesso', {}, {}, options)
export const useMutationRemoverAcesso = (config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/remover-acesso', {}, {}, options), config)

