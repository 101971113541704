import { queryObterPorSubdominio } from 'api/portal'

export function getId() {
  return window.sessionStorage.getItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function setId(tenantId) {
  return window.sessionStorage.setItem(process.env.REACT_APP_TENANT_ID_KEY, tenantId)
}

export function removeId() {
  return window.sessionStorage.removeItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function getSubdominio() {
  return process.env.NODE_ENV === 'production' ? window.location.host.split('.')[0] : 'medplus'
}

export function setSubdominio(subdominio) {
  return window.localStorage.setItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY, subdominio)
}

export function removeSubdominio() {
  return window.localStorage.removeItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY)
}

export async function access({ subdominio }) {
  return queryObterPorSubdominio({ subdominio }).then((data) => {
    setId(data.id)

    return data
  })
}

export function remove() {
  removeId()

  return Promise.resolve(null)
}

export function bootstrap() {
  const subdominio = getSubdominio()

  if (!subdominio) {
    return remove()
  }

  return access({ subdominio }).catch(remove)
}
